import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared-service.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HfButtonComponent, HfModelComponent, HfToastService } from '@monorepo/ui';
// import { HfButtonComponent } from '../../../../lib/ui/hf-button/hf-button.component';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [CommonModule, FormsModule, HfButtonComponent, HfModelComponent],
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})

export class PopupComponent implements OnInit, OnDestroy {

  popUpMessageDataSub: Subscription;
  popUpZipDataSub: Subscription;
  mainMessage: string = 'Error';
  subMessage: string = 'Somthing went wrong';
  showMessage: boolean = false;
  showZipSearch: boolean = false;
  showEmailFiled: boolean = false;
  zipCodes: any = [];
  eMailAddress: string = '';
  townZip: number = 11368;
  buttonText: string = 'Close';
  body: any;
  isEmailEmpty: boolean = false;
  isEmailInvalid: boolean = false;
  serchedZip: string = '';

  constructor(
    public sharedService: SharedService,
    public http: HttpClient,
    private toastService: HfToastService
  ) { }

  ngOnInit() {
    this.getPopUpData();
  }

  getPopUpData() {
    this.popUpMessageDataSub = this.sharedService.popUpMessageData$.subscribe((value) => {
      if (Object.keys(value).length != 0) {
        console.log(value);
        
        // this.body = document.getElementsByTagName('body')[0];
        // this.body.classList.add('disable-scroll');
        
        if (value.mainMessage) {
          this.mainMessage = value.mainMessage;
        }
        if (value.subMessage) {
          this.subMessage = value.subMessage;
        }
        if (value.showEmailFiled) {
          this.showMessage = true;
          this.buttonText = 'Submit';
          this.serchedZip = value.zipCode;
          this.showEmailFiled = true;
          this.sharedService.showPopup = true;
        } else {
          this.showMessage = false;
          this.showEmailFiled = false;
          this.sharedService.showPopup = false;
          this.toastService.toastMessage.next({title: this.mainMessage, msg: this.subMessage, type: 'success'});
        }
      }
    });

    this.popUpZipDataSub = this.sharedService.popUpZipSearch$.subscribe((value) => {
      if (Object.keys(value).length != 0) {
        // this.body = document.getElementsByTagName('body')[0];
        // this.body.classList.add('disable-scroll');
        this.mainMessage = 'Find deals for:'
        this.showZipSearch = true;
        this.zipCodes = value['zipCodes'];
        this.townZip = this.zipCodes[0]['postalCode'];
        this.sharedService.showPopup = true;
      }
    });
  }

  buttonClick() {
    if (this.buttonText == 'Close') {
      this.closePopUp();
    } else if (this.buttonText == 'Back') {
      this.backPopUp();
    } else if (this.buttonText == 'Submit') {
      this.submitEmail();
    } else {
      this.closePopUp();
    }
  }

  closePopUp() {
    this.sharedService.showPopup = false;
    this.showMessage = false;
    this.showZipSearch = false;
    this.buttonText = 'Close';
    this.body.classList.remove('disable-scroll');
    this.sharedService.popUpMessageData.next({});
    this.sharedService.popUpZipSearch.next({});
  }

  closeMessageModel(isClosed: boolean) {
    if (isClosed) {
      this.closePopUp();
    }
  }

  backPopUp() {
    this.showMessage = false;
    this.showZipSearch = true;
    this.buttonText = 'Close';
  }

  submitEmail() {
    let emailApiUrl = `${environment.api_url}customer/offer/notify-zip`;

    const headers = new HttpHeaders({
      'X-Api-Key': `${environment.api_key}`,
      'DAVOS-IsMobile': environment.isMobile.toString(),
    });

    this.isEmailEmpty = false;
    this.isEmailInvalid = false;
    if (this.eMailAddress == '') {
      this.isEmailEmpty = true;
    } else if (!this.sharedService.emailKey.test(this.eMailAddress)) {
      this.isEmailInvalid = true;
    } else {
      let payload = {
        'email': this.eMailAddress,
        'zipCode': this.serchedZip,
        'newsletterType': 0
      }
      this.http.post(emailApiUrl, payload, { headers }).subscribe((data) => {
        if (data) {
          this.buttonText = 'Close';
          this.showEmailFiled = false;
          this.sharedService.popUpMessageData.next(
            {
              mainMessage: 'Congratulations',
              subMessage: 'You subscribe successfully. Thank you for subscribing'
            }
          );
        }
      });
    }
  }

  preventAlphabets(event: any) {
    if ((event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 32) {
      event.preventDefault();
    }
  }

  checkZipCode() {
    const headers = new HttpHeaders({
      'X-Api-Key': `${environment.api_key}`,
      'DAVOS-IsMobile': environment.isMobile.toString(),
    });

    const url = `${environment.api_url}customer/offer/zipcodecheck?zipCode=${this.townZip}`;

    this.http.get(url, { headers: headers }).subscribe((res) => {
      if (res) {
        if (res['zipCodeExists'] == false) {
          this.buttonText = 'Back';
          this.sharedService.popUpMessageData.next(
            {
              mainMessage: 'Invalid ZIP Code',
              subMessage: 'Please enter a valid ZIP code.'
            }
          );
        } else {
          window.location.href = `${environment.secure_url}oil-select-provider/${this.townZip}/0/0/4/100`;
        }
      } else {
        this.sharedService.popUpMessageData.next(
          {
            showDefault: true
          }
        );
      }
    });
  }

  selectRadioItem(zip: any) {
    this.townZip = zip;
  }

  ngOnDestroy() {
    if (this.popUpMessageDataSub) {
      this.popUpMessageDataSub.unsubscribe();
    }
    if (this.popUpZipDataSub) {
      this.popUpZipDataSub.unsubscribe();
    }
    if (this.body) {
      this.body.classList.remove('disable-scroll');
    }
  }
}
