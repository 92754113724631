<div class="popup-main">
    <!-- <div class="popup-close-mask" (click)="closePopUp()"></div> -->
    <div class="popup-box">
        <hf-model [showModel]="showMessage || showZipSearch" [modelTitle]="mainMessage" (modelClosed)="closeMessageModel($event)" [modelSize]="'small'">
            <div class="popup-message-box text-align-center" *ngIf="showMessage">
                <p class="mb-10px" [innerHTML]="subMessage"></p>
                <ng-container *ngIf="showEmailFiled">
                    <div class="input email-filed" [ngClass]="isEmailEmpty || isEmailInvalid ? '' : 'set-margin'">
                        <p>Enter your email</p>
                        <input type="text" [(ngModel)]="eMailAddress">
                    </div>
                    <div class="soft-error" *ngIf="isEmailEmpty">
                        <p>E-mail Address required</p>
                    </div>
                    <div class="soft-error" *ngIf="isEmailInvalid">
                        <p>Please enter a valid email.</p>
                    </div>
                </ng-container>
            </div>

            <div class="zip-search-box" *ngIf="showZipSearch && !showMessage">
                <!-- <h3>Find deals for:</h3> -->
                <div class="search-box">
                    <img src="/assets/icons/01-Heatfleet.svg" width="35" height="35" class="top-serch-bar-img"
                        alt="Heatfleet" title="Heatfleet" />
                    <div class="zip-code-search" style="margin: 0; width: 55%;">
                        <div class="delivery-input">
                            <div class="input-icon">
                                <img src="/assets/icons/dark-location.svg" alt="Location icon" title="Location icon" />
                            </div>
                            <div class="input">
                                <p>Delivery ZIP</p>
                                <input type="text" maxlength="5" [(ngModel)]="townZip" (keydown)="preventAlphabets($event)">
                            </div>
                        </div>
                    </div>
                    <div class="search-button" style="width: 25%;">
                        <!-- <button (click)="checkZipCode()"> <img class="search-icon" src="/assets/icons/Search.svg"
                                alt="Search icon" title="Search icon" /></button> -->
                        <hf-button (click)="checkZipCode()" buttonType="icononly" iconUrl="assets/icons/Search.svg" buttonSize="medium"></hf-button>
                    </div>
                </div>
                <div class="radio-box">
                    <div class="radio-item" *ngFor="let item of zipCodes">
                        <input type="radio" name="zip" [value]="item.postalCode" [(ngModel)]="townZip">
                        <p (click)="selectRadioItem(item.postalCode)">{{item.postalCode}}</p>
                    </div>
                </div>
            </div>
            <!-- <button #btnFocus (click)="buttonClick()" (keyup.enter)="buttonClick()">{{buttonText}}
            </button> -->
            <hf-button #btnFocus [labelText]="buttonText" [buttonType]="'standard'" [buttonStyle]="'primary'"
                [buttonSize]="'medium'" (click)="buttonClick()" (keyup.enter)="buttonClick()"></hf-button>
        </hf-model>
</div>